<template>

    <div>
        <accept-on-account v-for="account in accounts" 
            :key="account.id"
            :account="account" 
        />
    </div>

</template>

<script>
import acceptOnAccount from './acceptOnAccount'
export default {
    components: {
        'accept-on-account': acceptOnAccount
    },
    async mounted() {
        let accounts = await this.$api.getAccounts()
        const brandIds = this.$store.getters.brands.filter(p => p.acceptViaDashboard).map(p => p.id)
        this.accounts = accounts.filter(p => brandIds.includes(p.brand.id))
    },
    data() {
        return {
            accounts: []
        }
    }
}
</script>
