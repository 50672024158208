<template>
    <div>

        <b>{{$t('labels.paymentUrl')}}</b>
        <p>{{$t('labels.transactionLifetime')}}</p>

        <p v-html="link"></p>

        <v-btn @click="copyTextToClipboard(link)">
            <v-icon left dark>{{copied ? 'mdi-check' : 'mdi-content-copy'}}</v-icon>
            {{copied ? $t('labels.copied') : $t('labels.copyLink')}}
        </v-btn>

        <v-btn @click="ok" color="primary">{{$t('labels.ok')}}</v-btn>
    </div>
</template>

<script>
export default {
    props: {
        link: String
    },
    data() {
        return {
            copied: false
        }
    },
    methods: {
        ok() {
            this.$emit('ok')
        },
        fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            
            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
        },
        copyTextToClipboard(text) {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(text);
            } else {
                navigator.clipboard.writeText(text)
                    .then(
                        () => console.log('Async: Copying to clipboard was successful!'),
                        () => console.error('Async: Could not copy text: ', err)
                    )
            }
            this.copied = true
            setTimeout(() => { this.copied = false }, 2000)
        }
    }
}
</script>
