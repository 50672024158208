<template>

<base-material-card>

        <template v-slot:heading>
            <div class="display-2 font-weight-light">
                <b>{{account.brand.name}}</b>
            </div>
        </template>

        <v-container>

            <v-text-field
                type="number"
                min="0.01"
                prefix="€"
                v-model.number="amount"
                :label="$t('labels.amount')"
            />
            

            <v-text-field
                v-if="account.brand.referenceVisible"
                type="text"
                v-model="reference"
                :label="$t('labels.reference')"
            />

            

            <v-btn 
                :loading="loading" 
                :disabled="disabled" 
                @click="createTrx" 
                color="primary">{{$t('labels.createTrx')}}</v-btn>

        </v-container>

    </base-material-card>
</template>

<script>
import link from './link'
export default {
    props: {
        account: Object
    },
    data() {
        return {
            amount: null,
            reference: '',
            loading: false
        }
    },
    computed: {
        disabled() {
            return this.loading || !this.amount
        }
    },
    methods: {
        createTrx() {
            this.loading = true
            this.$api.createTrx(this.account.id, this.account.brand.location.id, this.reference, this.amount)
                .then((trx) => { 
                    const paymentUrl = this.$env.VUE_APP_PAYMENTPAGE + trx.id
                    this.$openModal(link, { link: paymentUrl }, this).finally(() => {
                        this.amount = null
                    })
                 })
                .finally(() => { this.loading = false })
        }
    }
}
</script>
